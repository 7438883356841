import { Block } from 'components/Block'
import { Button } from 'components/button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { Icon } from 'components/icon'
import { Only } from 'components/only/Only'
import { Spacer } from 'components/spacer/Spacer'
import { Stack } from 'components/Stack'
import LogoWhite from 'images/extra-svg/LogoWhite.svg'
import { startDirectiveFlow } from 'logic/directive/create/start'
import { describeNavigation } from 'logic/navigation/describe'
import { FooterVariant } from 'logic/navigation/footer/types'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { mutateAnalyticsData } from 'utils/analytics'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { SUPPORT_URL } from 'utils/constants'
import {
  SButtonContainer,
  SDot,
  SFooter,
  SFooterActionsRow,
  SFooterContactLinksContainer,
  SFooterContactRow,
  SFooterLink,
  SFooterNavGroup,
  SFooterNavGroupTitle,
  SFooterNavRow,
  SFooterSocialStack,
  SFooterText,
  SHorizontalRuler,
} from './Footer.styled'

export interface IFooterProps {
  className?: string
  variant: FooterVariant
}
export function Footer({ className, variant }: IFooterProps) {
  const { isLogged } = useAuth()
  const { t } = useTranslation()

  const desc = describeNavigation().footer(variant)

  return (
    <Block className={className}>
      <SFooter>
        <Stack gap="1.5rem">
          <SFooterActionsRow>
            <Link href="/" passHref legacyBehavior>
              <a>
                <LogoWhite />
              </a>
            </Link>

            {!isLogged && (
              <SButtonContainer>
                <Button
                  type="button"
                  variant="secondary"
                  tone="dark"
                  href="/login"
                  size={['small', 'medium']}
                >
                  {t('common.login')}
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    startDirectiveFlow('not_yet_chosen', {
                      isLogged: false,
                      routerMechanism: 'push',
                      simpleFlowPlace: 'homepage',
                    })
                    mutateAnalyticsData({ button: 'footer' })
                  }}
                  size={['small', 'medium']}
                >
                  {t('common.get_started')}
                </Button>
              </SButtonContainer>
            )}
          </SFooterActionsRow>

          <SHorizontalRuler />

          <SFooterNavRow>
            {desc.getGroups().map((group) => (
              <SFooterNavGroup key={group.translationId}>
                <Stack gap="0.5rem" accurateSpacers>
                  <ConditionalWrapper
                    condition={!!group.url}
                    wrapper={(children) => (
                      <Link passHref href={group.url ?? ''} legacyBehavior>
                        {children}
                      </Link>
                    )}
                  >
                    <SFooterNavGroupTitle>
                      {t(`common.navigation.footer_headers.${group.translationId}.title`)}
                    </SFooterNavGroupTitle>
                  </ConditionalWrapper>
                  <Spacer size={[0.75, 1]} />
                  {group.items.map((item, i) => {
                    const url = item.url ?? group.url
                    return (
                      <ConditionalWrapper
                        key={i}
                        condition={!!url}
                        wrapper={(children) => (
                          <Link passHref href={url ?? ''} legacyBehavior>
                            {children}
                          </Link>
                        )}
                      >
                        <SFooterLink target={item.target}>
                          {t(`common.navigation.footer_children.${item.translationId}.title`)}
                        </SFooterLink>
                      </ConditionalWrapper>
                    )
                  })}
                </Stack>
              </SFooterNavGroup>
            ))}
          </SFooterNavRow>

          <SHorizontalRuler />

          <SFooterContactRow>
            <Stack
              gap={['0.5rem', '1rem']}
              orientation="horizontal"
              accurateSpacers
              css={{ alignItems: 'center' }}
            >
              <Only on="desktop">
                <SFooterText>
                  {t('common.navigation.footer_other.reserved', { year: new Date().getFullYear() })}
                </SFooterText>
              </Only>
              <Spacer axis="horizontal" size={1.5} className="desktop-only" />
              <Link passHref href="/terms-of-service" legacyBehavior>
                <SFooterLink>{t('common.terms_and_conditions')}</SFooterLink>
              </Link>
              <SDot />
              <Link passHref href="/privacy-policy" legacyBehavior>
                <SFooterLink>{t('common.privacy_policy')}</SFooterLink>
              </Link>
              <SDot />
              <Link passHref href={SUPPORT_URL} target="_blank" legacyBehavior>
                <SFooterLink>{t('common.contact_us')}</SFooterLink>
              </Link>
            </Stack>

            <Only on="mobile">
              <Spacer size={1.5} />
              <SFooterText>
                {t('common.navigation.footer_other.reserved', { year: new Date().getFullYear() })}
              </SFooterText>
            </Only>

            <SFooterContactLinksContainer>
              <SFooterSocialStack orientation="horizontal" gap="1rem">
                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/mygoodtrust">
                  <Icon name="Facebook" size="1.2rem" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/goodtrust/"
                >
                  <Icon name="LinkedIn" size="1.2rem" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://twitter.com/MyGoodTrust">
                  <Icon name="Twitter" size="1.2rem" />
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/mygoodtrust/">
                  <Icon name="Instagram" size="1.2rem" />
                </a>
              </SFooterSocialStack>
            </SFooterContactLinksContainer>
          </SFooterContactRow>
        </Stack>
      </SFooter>
    </Block>
  )
}
