import styled from '@emotion/styled'
import { Stack } from 'components/Stack'
import { Caption, Text } from 'components/Typography'
import { colors } from 'utils/colors'
import { BREAKPOINTS } from 'utils/styled'

export const SFooter = styled.footer`
  grid-column-start: left;
  grid-column-end: right;
  background: var(--c-gray100);

  display: flex;
  flex-direction: column;

  padding: 3rem 4rem;
  box-shadow: var(--card-shadow);
  border-radius: 2px 0 0 0;
  color: var(--c-gray500);

  @media ${BREAKPOINTS.MD.max} {
    padding: 1.5rem;
  }
`

export const SFooterActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SHorizontalRuler = styled.hr({
  width: '100%',
  borderColor: colors.gray[500],
  opacity: 0.12,
  margin: 0,
})

export const SFooterNavRow = styled.nav`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  row-gap: 2rem;
  column-gap: 1rem;

  @media ${BREAKPOINTS.MD.max} {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.25rem;
  }
`

export const SDot = styled.div({
  width: '2px',
  height: '2px',
  backgroundColor: colors.gray[500],
  borderRadius: 30,
})

export const SButtonContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  @media ${BREAKPOINTS.MD.max} {
    & > * {
      padding: 0.75rem;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
`

export const SFooterNavGroup = styled.div`
  color: var(--c-gray500);
  display: flex;
  flex-direction: column;
`

export const SFooterNavGroupTitle = styled(Caption.withComponent('a'))`
  color: white;
  text-decoration: none;
  white-space: nowrap;
  &[href]:hover {
    text-decoration: underline;
  }
  &:active,
  &:visited {
    color: white;
  }
`

export const SFooterText = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.93;
  color: currentColor;
`

export const SFooterLink = styled(SFooterText.withComponent('a'))`
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 143%;

  &[href]:hover {
    text-decoration: underline;
  }

  &:active,
  &:visited {
    color: currentColor;
  }
`

export const SFooterContactRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${BREAKPOINTS.MD.max} {
    flex-direction: column;
    justify-content: unset;
  }
`

export const SFooterSocialStack = styled(Stack)`
  display: flex;

  & a,
  & a:visited {
    color: inherit;
    display: flex;
  }

  @media ${BREAKPOINTS.MD.max} {
    order: -1;
    margin-top: 0;
    margin-bottom: 1rem;
  }
`

export const SFooterContactLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BREAKPOINTS.MD.max} {
    order: -1;
    margin-bottom: 1.5rem;
  }
`

export const SFooterLegalLinksContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`

export const SFooterLinkIcon = styled.span`
  display: inline-flex;
  margin-right: 13px;
  align-items: center;
  justify-content: center;
`
